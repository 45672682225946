<template>
  <div class="px-6">
    <!-- Acrobits component -->
    <acrobits domain :domain-id="domain"></acrobits>
  </div>
</template>

<script>
import acrobits from "@/views/pages/acrobits/acrobits";
export default {
  name: "root",
  props: ['domain'],
  components: {acrobits},
  beforeMount() {
    console.log(`domain: ${this.domain}`)
  }
}
</script>

<style scoped>

</style>